<template>
	<GenerateLoad />
</template>

<script>
const GenerateLoad = () => import(/* webpackChunkName: 'loadboard generate load'*/ "Components/loadboard/LoadboardGenerateLoad");
export default {
	components: {
		GenerateLoad,
	},
};
</script>

<style>
</style>